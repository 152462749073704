// Adapted from https://github.com/cra-template/pwa/blob/main/packages/cra-template-pwa/template/src/serviceWorkerRegistration.js

import { isLocalhost } from './helpers/isLocalHost';

// OnUpdateReady is a callback function that is executed when a new service worker is installed.
export type OnUpdateReady = () => void;

const ONE_MINUTE = 1000 * 60;
const serviceWorkerUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

export class ServiceWorkerHandler {
    installingWorker?: ServiceWorker | null = null;
    registration: ServiceWorkerRegistration | null = null;
    private onUpdateReady: OnUpdateReady | null = null;
    private isUserInitiatedUpdate = false;
    private refreshing = false;

    setIsUserInitiatedUpdate = () => {
        this.isUserInitiatedUpdate = true;
    };

    onStateChange = () => {
        // If the updated service worker insn't installed yet then there's nothing to do.
        if (this.installingWorker?.state !== 'installed') {
            return;
        }

        if (navigator.serviceWorker.controller) {
            // There is an previously installed service worker.
            // At this point, the updated precached content has been fetched, but the previous
            // service worker will still serve the older content until all client tabs are closed.
            console.log(
                'New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA.',
            );

            // Execute callback so a message can be displayed to the user that an update is
            // available.
            this.onUpdateReady?.();

            // Listen for controller change (which means the new service worker has been activated
            // by the user) and refresh the page.
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (!this.refreshing && this.isUserInitiatedUpdate) {
                    this.refreshing = true;
                    window.location.reload();
                }
            });
        } else {
            // There is no previously installed service worker.
            // At this point all assets have been precached for future use, and subsequent updates will
            // be made available to the user.
            console.log('Content is cached for offline use.');
        }
    };

    onUpdateFound = () => {
        // A new service worker has been found
        // Get the installing service worker that represents the latest version of the codebase
        this.installingWorker = this.registration?.installing;

        // Listen to updates to the state of the installing service worker
        this.installingWorker?.addEventListener('statechange', this.onStateChange);
    };

    registerValidServiceWorker = async () => {
        // Register the service worker available at the serviceWorkerUrl
        this.registration = await navigator.serviceWorker.register(serviceWorkerUrl);

        // Once registered, check for updates to the service worker file every 60 seconds
        setInterval(() => {
            this.registration?.update();
        }, ONE_MINUTE);

        // Listen for an update being available, if so then execute the onUpdateFound handler
        this.registration.addEventListener('updatefound', this.onUpdateFound);
    };

    checkValidServiceWorker = async () => {
        // Check if the service worker can be found. If it can't reload the page.
        try {
            const response = await fetch(serviceWorkerUrl, {
                headers: { 'Service-Worker': 'script' },
            });

            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');

            if (
                response.status === 404 ||
                (contentType != null && !contentType.includes('javascript'))
            ) {
                // No service worker found. Probably a different app. Reload the page.
                const registration = await navigator.serviceWorker.ready;
                await registration.unregister();
                window.location.reload();
            } else {
                // Service worker found. Proceed as normal.
                this.registerValidServiceWorker();
            }
        } catch (error) {
            console.log('No internet connection found. App is running in offline mode.');
        }
    };

    register = async (onUpdateReady: OnUpdateReady) => {
        this.onUpdateReady = onUpdateReady;

        if (isLocalhost()) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            return this.checkValidServiceWorker();
        }

        // Not localhost so just register the service worker.
        return this.registerValidServiceWorker();
    };
}
