import { Patient } from '@doc-abode/data-models';
import { FC } from 'react';
import { ViewToShow } from '../../../../../../constants/mainConst';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { useJobS1SyncStatusViewModel } from '../../../components/useJobS1SyncStatusViewModel';

interface IProps {
    patient: Patient;
    isBuddy: boolean;
    label: string;
    view: ViewToShow;
}
const S1SyncStatusLabel: FC<IProps> = ({ patient, isBuddy, label, view }) => {
    const { isS1SyncEnabled, getS1SyncStatusClassname } = useJobS1SyncStatusViewModel(patient);
    return (
        <ConditionalDisplay show={isS1SyncEnabled}>
            <span
                className={getS1SyncStatusClassname(view, isBuddy)}
                role={'status'}
                data-testid="s1-sync-status"
            >
                {label}
            </span>
        </ConditionalDisplay>
    );
};

export default S1SyncStatusLabel;
