// Adapted from https://medium.com/toplyne-engineering/pwa-update-notifications-in-a-react-application-f5680d51bb2

import { useState, useCallback, useEffect } from 'react';

import { ServiceWorkerHandler } from '../serviceWorkerHandler';

export const useServiceWorker = () => {
    const [isUpdateReady, setIsUpdateReady] = useState(false);
    const [serviceWorkerHandler, setServiceWorkerHandler] = useState<ServiceWorkerHandler | null>(
        null,
    );

    const onUpdateReady = useCallback(() => {
        setIsUpdateReady(true);
    }, [setIsUpdateReady]);

    const applyUpdate = () => {
        const installingWorkerState = serviceWorkerHandler?.installingWorker?.state;

        if (installingWorkerState === 'installed') {
            // If the service worker is installed then the user has already been prompted to update
            // and the service worker is waiting to be activated.
            serviceWorkerHandler?.setIsUserInitiatedUpdate();
            serviceWorkerHandler?.installingWorker?.postMessage({ type: 'SKIP_WAITING' });
        } else if (installingWorkerState === 'activated') {
            // If the service worker is activated then the user has already been prompted to update
            // and the service worker is already serving the updated content, we just need to reload
            // the page.
            window.location.reload();
        }
    };

    useEffect(() => {
        const serviceWorkerHandler = new ServiceWorkerHandler();
        serviceWorkerHandler.register(onUpdateReady);
        setServiceWorkerHandler(serviceWorkerHandler);
    }, [onUpdateReady, setServiceWorkerHandler]);

    return { isUpdateReady, applyUpdate };
};
