import { FC, useEffect, useMemo, useState } from 'react';
import { JobStatus, Patient, Service } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import { dateFormat } from '../../../../../constants/patientsConst';
import { isDisableFormBtn, getGenderWarning, getLanguageWarning } from '../../../../../helpers';
import {
    getDateEndTime,
    getMomentDateFormatter,
    getCareStepWarnings,
    getTimeFrameWarnings,
    checkLatestTimeWarning,
} from '../../../../../helpers/ucr';
import { useHcpSuggestionOptions } from '../../../../../hook/useHcpSuggestionOptions';
import useStores from '../../../../../hook/useStores';
import useVisitWarnings from '../../../../../hook/useVisitWarnings';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';

import { Button, ButtonSizes } from '../../../../v2/components';
import {
    DateInput,
    Select,
    TimeInput,
    DurationInput,
    Checkbox,
    TextArea,
    Text,
} from '../../../../v2/form';
import Warnings from '../../../../v2/form/Warnings';
import { HcpSelect } from '../../components';
import {
    abortStatusCheck,
    FormMode,
    FormSteps,
    getButtonName,
    friendlyCareComplexity,
    warningHighlight,
} from '../common';
import { isArrived, isCompleted, isAborted } from '../../../../../helpers/statusCheckHelper';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import { isHcpWorking } from '../../../../../helpers/ucr/isHcpWorking';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';

const requiredFields: Array<keyof FormikValues> = [
    'referralPathway',
    'disposition',
    'visitDate',
    'duration',
];

export type CareDetailsType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};
const CareDetails: FC<CareDetailsType> = ({
    values,
    loading,
    formMode,
    setFieldValue,
    onNextStep = () => {},
    onSaveForm = () => {},
}) => {
    const {
        RootStore: {
            usersStore: { hcpUsers },
            ucrStore: { hourStart, hourEnd, focusedJobId, warnings, updateWarnings },
            schedulesStore: { getSchedulesByDate },
            configStore: { pathways, adminTypes },
        },
    } = useStores() as { RootStore: any };
    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);
    /*CareDetails
        checkbox initial state
        value for earliestDateOfVisit:     enabled
        no value for earliestDateOfVisit:  disabled
    */
    const [isEarliestTimeChecked, setIsEarliestTimeChecked] = useState<boolean>(
        Boolean(values?.earliestDateOfVisit),
    );

    const visitWarnings = useVisitWarnings(values as VisitValuesType);
    const warningsToShow = focusedJobId === values.id ? warnings[focusedJobId] : visitWarnings;

    const [showUnavailableHcp, setShowUnavailableHcp] = useState(false);
    const [showUnavailableBuddyHcp, setShowUnavailableBuddyHcp] = useState(false);

    useEffect(() => {
        getSchedulesByDate(moment(values.visitDate).format('YYYY-MM-DD'));
    }, [values.visitDate, getSchedulesByDate]);

    const hcpSuggestionOptions = useHcpSuggestionOptions(values as VisitValuesType);

    // Prepare data for HCP1
    const statusOfJobHcp1 = values.jobStatus as JobStatus | undefined;
    const isHcp1Arrived = isArrived({ jobStatus: statusOfJobHcp1 });
    const isHcp1Completed = isCompleted({ jobStatus: statusOfJobHcp1 });
    const isHcp1Aborted = isAborted({ jobStatus: statusOfJobHcp1 });
    const showHcp1PostNotes = shouldShowPostJobNotes({ jobStatus: statusOfJobHcp1 });

    let hcp1AbortedReason = values.controllerAbortedReason;
    let hcp1AbortedNotes = values.controllerAbortedNotes;
    let hcp1AbortReasonFieldName = 'controllerAbortedReason';
    let hcp1AbortNotesFieldName = 'controllerAbortedNotes';

    if (statusOfJobHcp1 === JobStatus.HCP_ABORTED) {
        hcp1AbortedReason = values.hcpAbortedReason;
        hcp1AbortedNotes = values.hcpAbortedNotes;
        hcp1AbortReasonFieldName = 'hcpAbortedReason';
        hcp1AbortNotesFieldName = 'hcpAbortedNotes';
    }

    // Prepare data for HCP2
    const statusOfJobHcp2 = values.buddyJobStatus as JobStatus | undefined;
    const isHcp2Arrived = isArrived({ jobStatus: statusOfJobHcp2 });
    const isHcp2Completed = isCompleted({ jobStatus: statusOfJobHcp2 });
    const isHcp2Aborted = isAborted({ jobStatus: statusOfJobHcp2 });
    const showHcp2PostNotes = shouldShowPostJobNotes({ jobStatus: statusOfJobHcp2 });

    let hcp2AbortedReason = values.buddyControllerAbortedReason;
    let hcp2AbortedNotes = values.buddyControllerAbortedNotes;
    let hcp2AbortReasonFieldName = 'buddyControllerAbortedReason';
    let hcp2AbortNotesFieldName = 'buddyControllerAbortedNotes';

    if (statusOfJobHcp2 === JobStatus.HCP_ABORTED) {
        hcp2AbortedReason = values.buddyHcpAbortedReason;
        hcp2AbortedNotes = values.buddyHcpAbortedNotes;
        hcp2AbortReasonFieldName = 'buddyHcpAbortedReason';
        hcp2AbortNotesFieldName = 'buddyHcpAbortedNotes';
    }

    const isErrors = isDisableFormBtn(values, requiredFields);
    const buttonName = getButtonName(formMode, FormSteps.CARE);
    const disableStaff = formMode === FormMode.EDIT_VISIT && values.staffRequired === 2;

    const hcp = hcpUsers.find((hcp: any) => hcp.userId === values?.hcpId);
    const secondHcp = hcpUsers.find((hcp: any) => hcp.userId === values?.buddyId);

    const firstHcpGenderWarnings = hcp ? getGenderWarning(hcp, values.staffPreferredGender) : [];
    const secondHcpGenderWarnings = secondHcp
        ? getGenderWarning(secondHcp, values.staffPreferredGender)
        : [];
    const genderWarnings = [...firstHcpGenderWarnings, ...secondHcpGenderWarnings];
    const firstHcpLanguageWarning = hcp ? getLanguageWarning(hcp, values.languagesSpoken) : [];
    const secondHcpLanguageWarning = secondHcp
        ? getLanguageWarning(secondHcp, values.languagesSpoken)
        : [];
    const languageWarning = [...firstHcpLanguageWarning, ...secondHcpLanguageWarning];
    const [staffRequirements, setStaffRequirement] = useState(values.staffRequired);
    // todo make function for testing.
    const earliestDateOfVisitDisabled = !isEarliestTimeChecked || loading || isHcp1Arrived;
    // same logic as above so setting value to earliestDateOfVisitDisabled
    const availableFromDisabled = earliestDateOfVisitDisabled;
    const selectedPathway = pathways.find(
        (pathway: any) => pathway.value === values.referralPathway,
    );
    const serviceList =
        selectedPathway?.services
            .slice()
            .sort((serviceA: Service, serviceB: Service) =>
                serviceA.label.localeCompare(serviceB.label),
            ) || [];

    const disabledPathwayValue = !!pathways.find(
        ({ value, enabled }: { value: any; enabled: any }) =>
            value === values.referralPathway && !enabled,
    );

    const disabledServiceValue =
        selectedPathway?.services.find(
            ({ value, enabled }: { value: any; enabled: any }) =>
                value === values.disposition && !enabled,
        ) ||
        !selectedPathway?.services.find(
            ({ value }: { value: any }) => value === values.disposition,
        );

    const [highlightedWarnings, setHighlightedWarnings] = useState<any>({});

    const onSubmit = () => {
        if (!isErrors) {
            if (earliestDateOfVisitDisabled) {
                setFieldValue('availableFrom', undefined);
                setFieldValue('earliestDateOfVisit', undefined);
            }
            // would this be better as if (formMode === FormMode.EDIT_VISIT) first
            // includes or if we do above no need for this check unless
            // we actually need it to be in these form modes and we are planning on having some other form modes in the future
            if (
                formMode === FormMode.DEFAULT ||
                formMode === FormMode.ADD_VISIT ||
                formMode === FormMode.FOLLOW_UP
            ) {
                onNextStep(FormSteps.REVIEW, FormSteps.CARE);
            } else if (formMode === FormMode.EDIT_VISIT) {
                onSaveForm(FormSteps.CARE);
            }
        } else {
            // When we do have errors.  what will this end up doing -> setIsContinueBtnClicked
            setIsContinueBtnClicked(true);
        }
    };

    const MAXDATE = moment().add(1, 'year').toDate();
    const MIN_EARLIEST_DAY = new Date(1900, 1, 1);
    const MINTIME = useMemo(() => moment().startOf('day').hour(hourStart).toDate(), [hourStart]);
    const MAXTIME = useMemo(() => moment().startOf('day').hour(hourEnd).toDate(), [hourEnd]);

    const defaultStartTime = moment().startOf('minutes').toDate();

    const stepWarnings = useMemo(() => {
        let warns =
            formMode === FormMode.EDIT_VISIT || FormMode.ADD_VISIT
                ? [
                      ...checkLatestTimeWarning(values),
                      ...getCareStepWarnings(values, pathways, adminTypes),
                  ]
                : getCareStepWarnings(values, pathways, adminTypes);
        if (!isHcp1Completed) warns = warns.concat([...getTimeFrameWarnings(values)]);
        return warns;
    }, [formMode, values, pathways, adminTypes, isHcp1Completed]);

    useEffect(() => {
        const endTime = values.startTime
            ? getDateEndTime(values.startTime, values.duration)
            : moment().startOf('day').toDate();

        setFieldValue('endTime', endTime);
    }, [values.startTime, values.duration, setFieldValue]);
    // UPDATE earliestDateOfVisit based on availableFrom
    useEffect(() => {
        const isDisabled = !isEarliestTimeChecked || loading || isHcp1Arrived;
        if (!isDisabled) {
            // If we have no availableFrom value we set earliestDateOfVisit to undefined
            if (!values.availableFrom && values.earliestDateOfVisit !== undefined) {
                setFieldValue('earliestDateOfVisit', undefined);
            }
            // if we have a value for availableFrom and no earliestDateOfVisit we set earliestDateOfVisit to today
            // not sure if that's wise
            if (values.availableFrom && values.earliestDateOfVisit === undefined) {
                setFieldValue('earliestDateOfVisit', moment().toDate());
            }
        }
    }, [
        values.availableFrom,
        values.earliestDateOfVisit,
        setFieldValue,
        isEarliestTimeChecked,
        loading,
        isHcp1Arrived,
    ]);

    useEffect(() => {
        setHighlightedWarnings(
            warningHighlight({
                warnings: warningsToShow,
                stepWarnings: stepWarnings,
                values: values,
            }),
        );
    }, [stepWarnings, values, focusedJobId, warningsToShow]);

    useEffect(() => {
        if (formMode === FormMode.FOLLOW_UP && !values.hcpId && isHcp1Aborted) {
            setFieldValue('jobStatus', JobStatus.PENDING);
        }
        if (formMode === FormMode.FOLLOW_UP && !values.hcpId && !isHcp1Aborted) {
            setFieldValue('jobStatus', JobStatus.PENDING);
        }
        if (formMode === FormMode.FOLLOW_UP && values.hcpId && !isHcp1Aborted) {
            setFieldValue('jobStatus', JobStatus.ACCEPTED);
        }
        if (formMode === FormMode.FOLLOW_UP && !values.buddyId && isHcp2Aborted) {
            setFieldValue('buddyJobStatus', JobStatus.PENDING);
        }
        if (formMode === FormMode.FOLLOW_UP && !values.buddyId && !isHcp2Aborted) {
            setFieldValue('buddyJobStatus', JobStatus.PENDING);
        }
        if (formMode === FormMode.FOLLOW_UP && values.buddyId && !isHcp2Aborted) {
            setFieldValue('buddyJobStatus', JobStatus.ACCEPTED);
        }

        if (
            formMode === FormMode.EDIT_VISIT &&
            values.buddyId &&
            values.staffRequired === 2 &&
            values.buddyJobStatus === JobStatus.PENDING
        ) {
            setFieldValue('buddyJobStatus', JobStatus.ACCEPTED);
        }
        if (
            formMode === FormMode.EDIT_VISIT &&
            values.hcpId &&
            values.staffRequired === 2 &&
            values.jobStatus === JobStatus.PENDING
        ) {
            setFieldValue('jobStatus', JobStatus.ACCEPTED);
        }

        // VSU-1093 Creating follow ups from or editing now archived pathways need to set a new value
        if (
            (formMode === FormMode.FOLLOW_UP || formMode === FormMode.EDIT_VISIT) &&
            disabledPathwayValue
        ) {
            setFieldValue('referralPathway', undefined);
        }

        // VSU-1294 Creating follow ups from or editing now archived services need to set a new value
        if (
            (formMode === FormMode.FOLLOW_UP || formMode === FormMode.EDIT_VISIT) &&
            disabledServiceValue
        ) {
            setFieldValue('disposition', undefined);
        }

        if (values.hcpId && !isHcpWorking(hcpSuggestionOptions, values.hcpId)) {
            setShowUnavailableHcp(true);
        }

        if (values.buddyId && !isHcpWorking(hcpSuggestionOptions, values.buddyId)) {
            setShowUnavailableBuddyHcp(true);
        }
    }, [
        formMode,
        setFieldValue,
        values.hcpId,
        isHcp1Aborted,
        values.buddyId,
        isHcp2Aborted,
        values.staffRequired,
        values.jobStatus,
        values.buddyJobStatus,
        disabledPathwayValue,
        hcpSuggestionOptions,
        disabledServiceValue,
    ]);

    useEffect(() => {
        if (staffRequirements !== values.staffRequired) {
            if (formMode === FormMode.EDIT_VISIT && values.buddyId && values.staffRequired === 2) {
                setFieldValue('buddyJobStatus', JobStatus.ACCEPTED);
            }
            if (formMode === FormMode.EDIT_VISIT && !values.buddyId && values.staffRequired === 2) {
                setFieldValue('buddyJobStatus', JobStatus.PENDING);
            }
        }
    }, [
        formMode,
        setFieldValue,
        values.staffRequired,
        values.buddyId,
        staffRequirements,
        setStaffRequirement,
    ]);

    useEffect(() => {
        updateWarnings(values.id, visitWarnings || []);
    }, [values.hcpId, values.buddyId, updateWarnings, values.id, visitWarnings]);

    return (
        <div className="v2__form-block">
            <Select
                name="referralPathway"
                className="v2__form-group--pos-1-2"
                label="Pathway"
                required
                disabled={loading || disabledPathwayValue}
                isContinueBtnClicked={isContinueBtnClicked}
                onChange={(e) => {
                    setFieldValue('referralPathway', e.target.value);
                    setFieldValue('disposition', '');
                }}
            >
                <option value="">Select...</option>
                {pathways
                    .slice()
                    .sort((a: any, b: any) => a.label.localeCompare(b.label))
                    .map(
                        (pathway: any) =>
                            pathway.enabled && (
                                <option key={pathway.value} value={pathway.value}>
                                    {pathway.label}
                                </option>
                            ),
                    )}
            </Select>
            <Select
                name="disposition"
                className="v2__form-group--pos-1-2"
                label="Service required"
                required
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
            >
                <option value="">Select...</option>
                {serviceList.map(
                    (service: Service) =>
                        service.enabled && (
                            <option key={service.value} value={service.value}>
                                {service.label}
                            </option>
                        ),
                )}
            </Select>
            <Select
                name="careComplexity"
                className="v2__form-group--pos-1-2"
                label="Care complexity"
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
            >
                <option value="">Select...</option>
                {Object.keys(friendlyCareComplexity)
                    .sort((a, b) => a.localeCompare(b))
                    .map((careComplexity) => (
                        <option key={careComplexity} value={careComplexity}>
                            {friendlyCareComplexity[careComplexity]}
                        </option>
                    ))}
            </Select>
            <Checkbox
                name="carRequired"
                className="v2__form-group--pos-1-2"
                label="Transport requirements"
                title="Car required"
                disabled={loading}
                checked={values.carRequired}
            />
            <DateInput
                name="earliestDateOfVisit"
                className="v2__form-group--pos-1-3 v2__form-group--ml"
                label="Earliest date of visit"
                {...getMomentDateFormatter(dateFormat)}
                dateFormat={dateFormat}
                shouldClearValue={!isHcp1Arrived}
                disabled={earliestDateOfVisitDisabled}
                maxDate={MAXDATE}
                minDate={MIN_EARLIEST_DAY}
            />
            <TimeInput
                name="availableFrom"
                className="v2__form-group--pos-2-3"
                label="Earliest time of visit"
                defaultValue={MINTIME}
                defaultEnabled={!availableFromDisabled}
                onChangeCheckbox={(isChecked: boolean) => {
                    setIsEarliestTimeChecked(isChecked);
                }}
                selectAllOnFocus
                checkboxPosition="start"
                checkboxTestId="availableFromCheckbox"
                showCheckbox={true}
                disabled={availableFromDisabled}
            />
            <TimeInput
                name="availableTo"
                className="v2__form-group--pos-1-3"
                label="Latest time of visit"
                defaultValue={MAXTIME}
                selectAllOnFocus
                disabled={loading}
            />
            <DateInput
                name="visitDate"
                className="v2__form-group--pos-1-2"
                displayErrors={highlightedWarnings['visitDate']}
                label="Planned date of visit"
                {...getMomentDateFormatter(dateFormat)}
                dateFormat={dateFormat}
                required
                disabled={loading || isHcp1Arrived}
                isContinueBtnClicked={isContinueBtnClicked}
                maxDate={MAXDATE}
                minDate={MINTIME}
            />
            <TimeInput
                name="startTime"
                className="v2__form-group--pos-1-3"
                displayErrors={highlightedWarnings['startTime']}
                label="Planned start time"
                defaultValue={values.startTime || defaultStartTime}
                selectAllOnFocus
                disabled={loading}
                required={Boolean(values?.hcpId || values?.buddyId)}
            />
            <DurationInput
                name="duration"
                className="v2__form-group--pos-2-3"
                label="Planned duration"
                selectAllOnFocus
                disabled={loading}
                required
            />
            <TimeInput
                name="endTime"
                className="v2__form-group--pos-1-2"
                label="Expected end time"
                disabled
            />
            <TextArea
                name="notes"
                className="v2__form-group--pos-1-1"
                label="Pre-job notes"
                disabled={loading}
            />
            <Select
                name="staffRequired"
                className="v2__form-group--pos-1-2"
                label="Staffing requirements"
                required
                disabled={
                    loading || isHcp1Arrived || isHcp1Aborted || isHcp2Aborted || disableStaff
                }
                isContinueBtnClicked={isContinueBtnClicked}
                onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
                    const staffRequired = Number(changeEvent.target.value);
                    setFieldValue('staffRequired', staffRequired);
                    if (staffRequired === 1) {
                        setFieldValue('buddyId', '');
                        setFieldValue('buddyJobStatus', '');
                    }
                    setStaffRequirement(values.staffRequired);
                }}
            >
                <option value="1">1</option>
                <option value="2">2</option>
            </Select>

            <Checkbox
                name="Show unavailable"
                className="v2__form-group--pos-1-2"
                label="Staff member #1"
                title="Show unavailable"
                disabled={loading}
                checked={showUnavailableHcp}
                onChange={() => setShowUnavailableHcp(!showUnavailableHcp)}
                aria-label="show unavailable hcps"
            />
            <ConditionalDisplay show={values.staffRequired === 2}>
                <Checkbox
                    name="Show unavailable"
                    className="v2__form-group--pos-2-2"
                    label="Staff member #2"
                    title="Show unavailable"
                    disabled={loading}
                    checked={showUnavailableBuddyHcp}
                    onChange={() => setShowUnavailableBuddyHcp(!showUnavailableBuddyHcp)}
                    aria-label="show unavailable buddys"
                />
            </ConditionalDisplay>
            <HcpSelect
                patient={values as VisitValuesType}
                disabled={loading || isHcp1Completed || isHcp1Aborted}
                includeUnavailableHcps={showUnavailableHcp}
                ariaLabel="hcp select"
            />
            <ConditionalDisplay show={values.staffRequired === 2}>
                <HcpSelect
                    patient={values as VisitValuesType}
                    disabled={loading || isHcp2Completed || isHcp2Aborted}
                    isFirstUser={false}
                    includeUnavailableHcps={showUnavailableBuddyHcp}
                    ariaLabel="buddy select"
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={Boolean(values.jobStatus)}>
                <Text
                    name="jobStatus"
                    title="Status"
                    className="v2__form-group--pos-1-2"
                    description={Patient.getFriendlyVisitStatus(values.jobStatus as JobStatus)}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={values.staffRequired === 2 && values.buddyJobStatus}>
                <Text
                    name="buddyJobStatus"
                    title="Status"
                    className="v2__form-group--pos-2-2"
                    description={Patient.getFriendlyVisitStatus(values.buddyJobStatus as JobStatus)}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT && showHcp1PostNotes}>
                <TextArea
                    name="postVisitNotes"
                    className={
                        values.staffRequired === 2
                            ? 'v2__form-group--pos-1-2'
                            : 'v2__form-group--pos-1-1'
                    }
                    label={values.staffRequired === 2 ? 'Post-job notes #1' : 'Post-job notes'}
                    disabled={loading}
                />
            </ConditionalDisplay>
            <ConditionalDisplay
                show={
                    formMode === FormMode.EDIT_VISIT &&
                    showHcp2PostNotes &&
                    values.staffRequired === 2
                }
            >
                <TextArea
                    name="postVisitNotesBuddy"
                    className="v2__form-group--pos-2-2"
                    label="Post-job notes #2"
                    disabled={loading}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT && values.arrivedDateTime}>
                <TimeInput
                    name="arrivedDateTime"
                    className="v2__form-group--pos-1-4"
                    label="Actual start"
                    defaultValue={new Date(values.arrivedDateTime)}
                    selectAllOnFocus
                    required
                    disabled={loading || isHcp1Arrived}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT && values.finishedDateTime}>
                <TimeInput
                    name="finishedDateTime"
                    className="v2__form-group--pos-2-4"
                    label="Actual end"
                    defaultValue={new Date(values.finishedDateTime)}
                    selectAllOnFocus
                    required
                    disabled={loading || isHcp1Arrived}
                />
            </ConditionalDisplay>
            <ConditionalDisplay
                show={formMode === FormMode.EDIT_VISIT && values.buddyArrivedDateTime}
            >
                <TimeInput
                    name="buddyArrivedDateTime"
                    className="v2__form-group--pos-3-4"
                    label="Actual start"
                    defaultValue={new Date(values.buddyArrivedDateTime)}
                    selectAllOnFocus
                    required
                    disabled={loading || isHcp2Arrived}
                />
            </ConditionalDisplay>
            <ConditionalDisplay
                show={formMode === FormMode.EDIT_VISIT && values.buddyFinishedDateTime}
            >
                <TimeInput
                    name="buddyFinishedDateTime"
                    className="v2__form-group--pos-4-4"
                    label="Actual end"
                    defaultValue={new Date(values.buddyFinishedDateTime)}
                    selectAllOnFocus
                    required
                    disabled={loading || isHcp2Arrived}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={isHcp1Aborted}>
                <div className="v2__form-group--pos-1-2">
                    <Text
                        name={hcp1AbortReasonFieldName}
                        title="Aborted Reason"
                        description={hcp1AbortedReason}
                    />
                    <Text
                        name={hcp1AbortNotesFieldName}
                        title="Aborted Notes"
                        description={hcp1AbortedNotes}
                    />
                </div>
            </ConditionalDisplay>
            <ConditionalDisplay show={isHcp2Aborted}>
                <div className="v2__form-group--pos-2-2">
                    <Text
                        name={hcp2AbortReasonFieldName}
                        title="Aborted Reason"
                        description={hcp2AbortedReason}
                    />
                    <Text
                        name={hcp2AbortNotesFieldName}
                        title="Aborted Notes"
                        description={hcp2AbortedNotes}
                    />
                </div>
            </ConditionalDisplay>
            <div className="v2__form-group--pos-1-1">
                {/* WARNINGS */}
                <ConditionalDisplay show={!abortStatusCheck(values?.jobStatus)}>
                    <Warnings
                        warnings={warningsToShow}
                        stepWarnings={stepWarnings.concat([...genderWarnings, ...languageWarning])}
                    />
                </ConditionalDisplay>
            </div>
            <label className="v2__form-group">
                <Button
                    className="v2__form-submit-button"
                    name={buttonName}
                    size={ButtonSizes.MEDIUM}
                    clickEvent={onSubmit}
                    type="submit"
                />
            </label>
        </div>
    );
};
const forTestingCareDetails = { CareDetails };

export { forTestingCareDetails };
export default observer(CareDetails);
