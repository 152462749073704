import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { configure } from 'mobx';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { createBrowserHistory } from 'history';

import './assets/index.scss';
import packageInfo from '../package.json';
import reportWebVitals from './reportWebVitals';
import config from './config';
import { AppShell } from './components/AppShell';
import { host } from './api/baseApi';

const version = packageInfo.version;

const {
    REACT_APP_ENVIRONMENT: ENV,
    NODE_ENV,
    SENTRY_TRACES_SAMPLE_RATE,
    SENTRY_PROFILES_SAMPLE_RATE,
    SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
} = process.env;

const {
    ga: { measurementId },
    hotjar: { hjid },
} = config();

if (measurementId) {
    ReactGA.initialize(measurementId);
    ReactGA.send('pageview');
}

if (hjid) {
    hotjar.initialize(hjid, 6);
}

// if (NODE_ENV === 'production') {
const history = createBrowserHistory();

Sentry.init({
    environment: ENV,
    release: version,
    dsn: 'https://cd010e53aafe4398a06efecf434ef3fc@o421207.ingest.sentry.io/5340722',
    integrations: [
        Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: parseFloat(SENTRY_TRACES_SAMPLE_RATE ?? '1.0'),
    profilesSampleRate: parseFloat(SENTRY_PROFILES_SAMPLE_RATE ?? '1.0'),
    replaysSessionSampleRate: parseFloat(SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? '1.0'),
    replaysOnErrorSampleRate: parseFloat(SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? '1.0'),
    tracePropagationTargets: [host],
});
// }

configure({ enforceActions: 'never' });
library.add(faEllipsisV);

const Root = (
    <React.StrictMode>
        <AppShell />
    </React.StrictMode>
);

ReactDOM.render(Root, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log('Build version 3.57.0 generated at: Wed Jun 12 2024 14:22:11 GMT+0100 (British Summer Time)');
