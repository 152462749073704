import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { Card, Button, FormGroup, Tag } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

import Loader from '../../modules/helpers/Loader';
import MapView, { colors } from './MapView';
import {
    formatDisplayDate,
    parseDateStringToJSDate,
    formatDisplayDateTime,
    metresToMiles,
} from '../../modules/helpers/formatData';

const routeStatusMappings = {
    PENDING: 'Pending',
    AVAILABLE: 'Unconfirmed',
    ACCEPTED: 'Accepted',
    CURRENT: 'Current',
    ARRIVED: 'In progress',
    COMPLETED: 'Completed',
    HCP_ABORTED: 'Aborted by HCP',
    CONTROLLER_ABORTED: 'Aborted by Controller',
};

const routeStatusTags = {
    AVAILABLE: 'warning',
    ACCEPTED: 'success',
    CURRENT: 'primary',
    ARRIVED: 'primary',
    HCP_ABORTED: 'danger',
    CONTROLLER_ABORTED: 'danger',
};

const MapExperimental = inject('RootStore')(
    observer(
        class MapExperimental extends Component {
            state = {
                selectedDate: new Date(),
                focusedItineraryId: null,
            };

            _handleDateChange = (date) =>
                this.setState({ selectedDate: date, focusedItineraryId: null });

            _handleFocusRoute = (id) =>
                this.setState({
                    focusedItineraryId: id === this.state.focusedItineraryId ? null : id,
                });

            _handleViewRouteDetails = (id) => this.props.history.push(`/vaccinations/routes/${id}`);

            render() {
                const {
                    routesStore: { allJobs, loading },
                    configStore: { clientKeys },
                } = this.props.RootStore;
                const { selectedDate, focusedItineraryId } = this.state;

                const routes = allJobs.filter(
                    (job) =>
                        moment(selectedDate).isSame(moment(job.itinerary.route.startTime), 'day') &&
                        job.jobStatus !== 'CONTROLLER_ABORTED',
                );

                return (
                    <>
                        <aside className="vaccinations__tools">
                            <h3 className="h3">Date</h3>
                            <FormGroup>
                                <DateInput
                                    formatDate={formatDisplayDate}
                                    parseDate={parseDateStringToJSDate}
                                    onChange={this._handleDateChange}
                                    value={selectedDate}
                                    fill
                                />
                            </FormGroup>
                            <h3 className="h3">Routes</h3>
                            {routes.map(
                                (
                                    {
                                        id,
                                        nominatedHcps,
                                        itineraryId,
                                        jobStatus,
                                        itinerary: {
                                            route: {
                                                totalTravelDistance,
                                                totalTravelTime,
                                                startTime,
                                                endTime,
                                                wayPoints,
                                            },
                                        },
                                    },
                                    index,
                                ) => {
                                    const focused = focusedItineraryId === itineraryId;
                                    return (
                                        <Card
                                            key={id}
                                            className="create-routes__summary create-routes__summary--snug"
                                            style={{ borderBottomColor: colors[index] }}
                                        >
                                            <Tag
                                                intent={routeStatusTags[jobStatus]}
                                                minimal={jobStatus !== 'COMPLETED'}
                                                className="create-routes__summary-tag"
                                            >
                                                {routeStatusMappings[jobStatus]}
                                            </Tag>
                                            <p>
                                                <strong>HCP:</strong> {nominatedHcps[0]}
                                            </p>
                                            <p>
                                                <strong>Patients:</strong> {wayPoints.length}
                                            </p>
                                            <p>
                                                <strong>Start:</strong>{' '}
                                                {formatDisplayDateTime(startTime)}
                                            </p>
                                            <p>
                                                <strong>End:</strong>{' '}
                                                {formatDisplayDateTime(endTime)}
                                            </p>
                                            <p>
                                                <strong>Total travel time:</strong>{' '}
                                                {totalTravelTime}
                                            </p>
                                            <p>
                                                <strong>Total travel distance:</strong>{' '}
                                                {metresToMiles(totalTravelDistance)}
                                            </p>
                                            <Button
                                                onClick={() => this._handleViewRouteDetails(id)}
                                                text="View details"
                                                icon="route"
                                                className="create-routes__summary-btn"
                                            />
                                            <Button
                                                onClick={() => this._handleFocusRoute(itineraryId)}
                                                text="Focus"
                                                icon="eye-open"
                                                active={focused}
                                                className="create-routes__summary-btn"
                                            />
                                        </Card>
                                    );
                                },
                            )}
                        </aside>
                        <main className="vaccinations__main vaccinations__main--full">
                            {loading || !clientKeys.googleMaps ? (
                                <Loader fullscreen={false} />
                            ) : (
                                <MapView
                                    routes={routes}
                                    focusedItineraryId={focusedItineraryId}
                                    apiKey={clientKeys.googleMaps}
                                />
                            )}
                        </main>
                    </>
                );
            }
        },
    ),
);

export default MapExperimental;
